<!-- (c) 2021 Cofense Inc. -->
<template>
  <portal to="app-modal">
    <transition name="koi_a-fade">
      <koi-modal
        v-if="$sessionTimeout.alertOperator"
        id="session-expiration-modal"
        intent="error"
        size="sm"
        :is-dismissable="false"
        data-qa="session-timeout-modal"
      >
        <template #title>
          <slot name="title">
              TODO: Insert 
              <koi-code>
                {{ '<template #title />' }}
              </koi-code>
          </slot>
        </template>
        <template #content>
          <slot v-bind="{ secondsRemaining }" name="content">            
            TODO: Insert 
            <koi-code>
              {{ `<template #content="{ /* secondsRemaining */ }" />` }}
            </koi-code>
            : <koi-text>{{ secondsRemaining }}</koi-text>
          </slot>
        </template>
        <template #control-group-right>
          <koi-button
            intent="danger"
            :disabled="!secondsRemaining || $sessionTimeout.isExtending"
            :loading="$sessionTimeout.isExtending"
            data-qa="extend-session-button"
            @click.native="$sessionTimeout.extendSession"
          >
            <slot name="control">
              TODO: Insert 
              <koi-code>
                {{ '<template #control />' }}
              </koi-code>
            </slot>
          </koi-button>
        </template>
      </koi-modal>
    </transition>
  </portal>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'session-timeout-modal',

    data() {
      return {
        secondsRemaining: -1,
        timerId: -1,
      };
    },

    watch: {
      '$sessionTimeout.alertOperator': function watcher(alertOperator: boolean) {
        if (alertOperator) {
          this.syncSecondsRemaining();

          this.timerId = window.setInterval(
            () => {
              this.syncSecondsRemaining();

              if (this.secondsRemaining <= 0) {
                clearTimeout(this.timerId);
              }
            },
            1000,
          );
        } else {
          clearTimeout(this.timerId);
        }
      },
    },

    methods: {
      syncSecondsRemaining(): void {
        this.secondsRemaining = Math.floor((
          this.$sessionTimeout.sessionExpiration.getTime() - Date.now()
        ) / 1000);
      },
    },
  });
</script>
